// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1680px) and (min-width: 1280px) {
    .inner {
        width: 1170px;
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////
    :root {
        --prodGap: 30px;
        --rowGap: 30px;
    }

    .mainmenu a {
        font-size: 15px;
        padding: 0 10px;
    }

    #search-engine {
        margin-left: 0;
    }

    .header-tools-wrapper {
        gap: 20px;
    }

    .product-add {
        width: 70px;
        font-size: 13px;
    }

    .footer-item.contact {
        width: 62%;
    }

    .detail-main-columns {
        gap: 30px;
    }

    .detail-small-columns {
        gap: 30px;
    }

    .detail-amount {
        width: 118px;
    }

    .round-banner a .text-wrapper {
        font-size: 14px;
        padding: 10px 30px;

        span {
            max-height: calc(2 * (1.2 * 14px));
        }
    }
}