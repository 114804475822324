// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1100px) {
    // CART
    .check-person {
        margin-top: 20px;
    }

    .cart-final-boxes {
        display: flex;
        flex-direction: column;
    }

    .checkbox-inline {
        padding: 0 0 15px;
    }

    .navigation-wrapper {
        margin-top: 5px;
    }

    .checkbox-wrapper {
        padding: 10px;
    }

    .cart-checkbox-main-wrapper .has-many-checkboxes .cart-check-all {
        margin-bottom: 0;
    }

    .cart-checkbox-main-wrapper .has-many-checkboxes .cart-check-all ~ * {
        padding-left: 10px;
    }

    .fields-required-text {
        padding-top: 5px;
    }

    .oauth-signup {
        gap: 10px;
    }

    .oauth-signup > span {
        padding: 20px 0 0;
    }

    .oauth-signup > a.confirm-button {
        font-size: 0;
        padding: 0;
        width: 50px;
        min-width: 0;

        svg {
            margin: 0 !important;
        }
    }

    .goshop-modal {
        padding: 20px 15px;
    }

    .comments-list {
        gap: 20px;
    }

    .comment .author:before {
        display: none;
    }

    .comment .added-date {
        margin-left: 0;
        width: 100%;
    }

    .comment .purchase-confirmed {
        width: 100%;
    }

    .field-wrapper:not(.checking-reg),
    .to-show .field-wrapper:last-of-type {
        margin-bottom: 15px !important;
    }

    // OTHER
    #cookies-license {
        bottom: 0;
        right: 0;
        width: 100%;
        max-width: none;
        padding: 10px 10px 10px 80px;

        &:before {
            left: 18px;
        }
    }

    .goshop-modal.goshop-modal-form {
        width: 90vw !important;
        max-width: none !important;
    }

    .goshop-modal:not(.goshop-modal-form),
    .modal-form-content {
        padding: 30px 10px !important;
    }

    .cart-success .goshop-modal, .availability-modal-wrapper .goshop-modal {
        padding: 0 !important;
    }

    .payment-channels-modal .goshop-modal {
        padding: 0 !important;
    }

    .availability-modal-inner {

        .img-wrapper {
            display: none;
        }
    }

    .order-detailed-info .item-box-small,
    .order-detailed-info .item-box-medium {
        flex-basis: 100%;
        border-right: 0;
    }

    #detail-traits td {
        padding: 8px 15px;
    }

    .payment-channels-modal .goshop-modal .wrapper {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////
    .translator-wrapper {
        display: none;
    }

    .header-contact {

        li:nth-child(2) {
            display: none;
        }
    }

    .swiper-pagination.banner-pagination .swiper-pagination-bullet {
        height: 30px;
    }

    .main-banner b {
        font-size: 18px;
        padding: 8px;
        bottom: 30px;
    }

    .static-banner {
        gap: 10px;

        div {
            padding: 10px;
        }
    }

    .product {
        gap: 10px;
    }

    .product-bottom-wrapper {
        flex-wrap: wrap;
        gap: 10px;
    }

    .product-price {
        flex-direction: row;
        width: 100%;
        gap: 5px;
        height: auto;
    }

    .product-add {
        width: auto;
        flex-grow: 1;
    }

    .round-banner .wrapper {
        gap: 10px;
    }

    .round-banner a .text-wrapper {
        height: 42px;
        padding: 5px 60px;
    }

    .round-banner a .text-wrapper span {
        font-size: 12px;
        max-height: calc(2 * (1.2 * 12px));
        top: -2px;
    }

    .newsletter-header b {
        font-size: 22px;
    }

    .footer-item.contact .main-contact-box {
        flex-direction: column;
    }

    .detail-small-columns {
        gap: 30px;
    }

    .detail-small-right-column {
        width: 250px;
    }

    .detail-amount {
        width: 99px;
    }

    .detail-accordion-trigger {
        font-size: 16px;
    }

    .products-list.wide {
        gap: 20px;
    }

    #detail-tabs nav {
        border-bottom: 1px solid @border-color;
        margin-bottom: 15px;
        gap: 5px;
        flex-wrap: nowrap;

        button {
            border-bottom: 0;
            font-size: 15px;
            background-color: @border-color;
            color: darken(@border-color, 20%);
            padding: 10px 15px;
            z-index: 1;
            top: 1px;

            &[aria-selected="true"] {
                color: white;
                background-color: @main-color;
            }
        }
    }

    @media (max-width: 840px) {
        .round-banner
        a .text-wrapper {
            padding: 5px 50px;
        }
    }

    .round-banner
    a .text-wrapper {
        padding: 5px 80px;
    }


}

@media (min-width: 601px) and (max-width: 1100px) {
    .main-page-products-wrapper .product:nth-child(4) {
        display: none;
    }
}